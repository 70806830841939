import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: 'https://9a6f2e2dd0324a6e898b1a275d0afb88@o510915.ingest.us.sentry.io/5607267',
  release: process.env.SENTRY_RELEASE,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: [
    'healthlabs.com',
    'healthlabs.local',
    'www.healthlabs.com',
    'www.healthlabs.local',
    'checkout.healthlabs.com',
  ],
  integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      order_id: localStorage.getItem('transaction_id') ?? null,
    };

    return event;
  },
  ignoreErrors: ['BraintreeError: Cannot contact the gateway at this time.'],
});
